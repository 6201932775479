import React from "react";
import styled from "styled-components";
import Head from "../components/Head";
import PageContainer from "../components/PageContainer";
import Section from "../components/Section";

const ImageContainer = styled.div`
  @media (min-width: 769px) {
    margin: 2rem auto 0 auto;
    width: 24rem;
  }
`;
const Image = styled.img`
  width: 100%;
`;

function About() {
  return (
    <>
      <Head title="Katherine Tillotson - About" />
      <PageContainer reduceBackgroundOpacity>
        <Section>
          <p>
            I was born in Minneapolis, Minnesota and grew up near lakes and
            surrounded by trees. Now I live in San Francisco, with my husband,
            in a house on a hill.
          </p>
          <p>
            My studio is in a cozy corner of the house, a space filled with
            brushes and bottles, scraps of paper and paints. My dogs, Sookie and
            Chi, keep me company while I work. They nap and watch out the window
            for visitors, then nudge me with their cold black noses in the late
            afternoon when it is time for a walk.
          </p>
          <p>
            I remember the first time I painted a picture and the paint went
            where I wanted it to go. I was in third grade, working with
            watercolors and was very excited by the experience. Now, I use all
            kinds of paint, charcoal, pens and paper. I like playing with the
            materials and they all provide seemingly endless possibilities for
            expression.
          </p>
          <p>
            Picture books fill many of the bookcases in our house and have
            always held a special place in my heart. I love this form of
            storytelling that pairs carefully chosen words with imaginative
            illustrations and binds them together in a package for hands to
            hold.
          </p>
          <p>
            I have been creating picture books for many years now and the work
            continues to give me the same pleasure and excitement I felt so long
            ago in grade school when I brushed paint on paper and watched it
            form a tree.
          </p>
          <ImageContainer>
            <Image
              src="https://www.katherinetillotson.com/images/img_9852-676.jpg"
              alt="Katherine Tillotson holding her book."
            />
          </ImageContainer>
        </Section>
      </PageContainer>
    </>
  );
}

export default About;
